import { useEffect, useReducer, useRef, useContext } from 'react'
import { Grid, Tabs, Tab } from '@achieve/sunbeam'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import classNames from 'classnames'
import styles from './StickyTabs.module.scss'
import { scrollTo } from 'utils/shared'

// NOTE: these values need to match the heights of the Header in order for the StickyTabs to look
// aligned correctly once stuck.
const DEFAULT_MOBILE_TOP_POSITION = 54
const DEFAULT_DESKTOP_TOP_POSITION = 64

const stickyTabsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, value: action.newValue, isTransitioning: action.isTransitioning || false }
    case 'SET_TRANSITIONING':
      return { ...state, isTransitioning: action.isTransitioning }
    case 'SET_STUCK':
      return { ...state, isStuck: action.isStuck }
    default:
      return state
  }
}

/**
 * Wrapper component that renders Ascend/MUI Tabs that stick to the page and dynamically sets the
 * active tab based on scroll to match the content that is currently in the viewport
 *
 * @param {object} props StickyTabs props
 *   @param {boolean} [props.allowScrollButtonsMobile=true] Determines if scroll buttons show on mobile.
 *   @param {string} [props.className] Optional className that attaches to the parent element.
 *   @param {boolean} [props.isMobile=false] Use mobile styling/logic for StickyTabs component.
 *   @param {[{
 *      tabLabel: string,
 *      tabItem: JSX.Element
 *   }]} props.stickyTabsContent List of objects for each tab content.
 *   @param {number} [props.top] Optional value for custom sticky positioning
 *
 * @returns JSX.Element
 */
function StickyTabs({
  allowScrollButtonsMobile = true,
  className,
  isMobile = false,
  stickyTabsContent,
  top,
}) {
  const [{ isStuck, value }, dispatch] = useReducer(stickyTabsReducer, {
    isStuck: false,
    isTransitioning: false,
    value: stickyTabsContent?.[0]?.tabLabel,
  })
  const { dispatch: dispatchEvent } = useContext(AnalyticsContext)

  const containerRef = useRef()
  const tabsRef = useRef()
  const itemsRef = useRef([])
  itemsRef.current = []

  const tabsOffset = top ?? (isMobile ? DEFAULT_MOBILE_TOP_POSITION : DEFAULT_DESKTOP_TOP_POSITION)

  const handleTabChange = (event, newValue) => {
    // Set isTransitioning to block the observer while the active tab content is scrolling
    dispatch({ type: 'SET_VALUE', newValue, isTransitioning: true })

    dispatchEvent({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: {
        ...handleTrackAndReactEvent(
          event,
          updateTrackWithListedEvent({ list_name: 'STICKY SUBNAV' })
        ),
      },
    })

    // Find the related tab content
    const targetItem = itemsRef.current.find((tabItem) => tabItem?.dataset?.tabLabel === newValue)

    // Determine the page scroll offset so the active tab content is in the viewport
    const scrollOffset =
      // top of target tab child to top of the StickyTab section
      targetItem.getBoundingClientRect().top +
      // current window scrollY value
      window.scrollY -
      // StickyTabs component height
      tabsRef.current.getBoundingClientRect().height -
      // StickyTabs offset (main header height)
      tabsOffset

    // Scroll the page and provide the scrollTo function a callback to turn off isTransitioning
    // and allow the observer to fire tab change events again
    scrollTo(scrollOffset, () => dispatch({ type: 'SET_TRANSITIONING', isTransitioning: false }))
  }

  useEffect(() => {
    const handleStickyState = () => {
      const positionContainerRef = containerRef.current.getBoundingClientRect()

      // 4 is used for these min/max values to offset 2px highlight border width and 1px tab
      // container border width
      const isWithinStickyMin = positionContainerRef.top <= 4
      const isWithinStickyMax = positionContainerRef.bottom >= 4

      const newStuck = isWithinStickyMin && isWithinStickyMax

      // Dispatch a change for isStuck if it is different than the current state
      isStuck !== newStuck && dispatch({ type: 'SET_STUCK', isStuck: newStuck })
    }

    const handleTabState = () => {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const isHorizontal = windowWidth >= windowHeight

      // Find the active sticky tabs item based on scroll position
      const activeItem =
        itemsRef.current.find((item) => {
          const itemPosition = item.getBoundingClientRect()
          return (
            windowHeight - itemPosition.top > windowHeight * (isHorizontal ? 0.6 : 0.8) &&
            itemPosition.top > 0
          )
        }) || {}

      // Get the label for the active item
      const targetTabLabel = activeItem.dataset?.tabLabel

      // Dispatch a change for value if it is different than the current state
      targetTabLabel &&
        targetTabLabel !== value &&
        dispatch({ type: 'SET_VALUE', newValue: targetTabLabel })
    }

    const handleScroll = () => {
      handleStickyState()
      handleTabState()
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isStuck, value, stickyTabsContent, itemsRef])

  if (!stickyTabsContent) return null

  return (
    <div
      ref={containerRef}
      data-stuck={isStuck}
      className={classNames(styles['sticky-tabs-outer-container'], className)}
    >
      <Grid
        container
        style={{ top: tabsOffset }}
        className={styles['sticky-tabs-container']}
        data-testid="sticky-tabs-container"
      >
        <div ref={tabsRef}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable" // Note: scrollable cannot be paired with "centered" prop
            scrollButtons="auto"
            className={styles['tabs']}
            allowScrollButtonsMobile={allowScrollButtonsMobile}
            aria-label="sticky scrollable tabs component"
          >
            {stickyTabsContent.map(({ tabLabel }, i) => (
              <Tab key={`tab-${i}`} label={tabLabel} value={tabLabel} data-testid={`tab-${i}`} />
            ))}
          </Tabs>
        </div>
      </Grid>
      {stickyTabsContent.map(({ tabLabel, tabItem }, i) => (
        // Provide a wrapper for each content item for two purposes:
        // 1. Add to itemsRef array so the IntersectionObserver has a list of react dom elements
        // 2. Add a data attribute with the same value as the corresponding label for easy lookup
        // when trying to determine what actions to take once an item `isIntersecting` or which item
        // to scroll to if a tab is clicked.
        <div data-tab-label={tabLabel} key={i} ref={(el) => el && itemsRef.current.push(el)}>
          {tabItem}
        </div>
      ))}
    </div>
  )
}

export { StickyTabs }
