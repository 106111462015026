import { useEffect, useRef, useState } from 'react'
import useIsSsr from 'hooks/useIsSsr'
import { Slide } from '@material-ui/core'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { AchieveTheme } from '@achieve/sunbeam'

function SlideOnVisible({
  duration = 1600,
  timeout = 700,
  scrollOnMobile = true,
  direction = 'up',
  children,
  easing,
}) {
  const isSsr = useIsSsr()
  const [isVisible, setIsVisible] = useState(false)
  const currentRef = useRef()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSsr])
  const scrollHandler = () => {
    const borderTop = currentRef?.current?.getBoundingClientRect()?.y
    if (borderTop <= window.innerHeight) {
      setTimeout(() => setIsVisible(true), timeout)
    }
  }
  if (!scrollOnMobile && isMobile) {
    return <div ref={currentRef}>{children}</div>
  }
  return (
    <div
      ref={currentRef}
      style={{
        opacity: isVisible ? 1 : 0,
        transition: `${(AchieveTheme?.transitions?.duration?.short || 0) * 4}ms ${
          AchieveTheme?.transitions?.easing?.easeInOut
        }`,
      }}
    >
      <Slide
        direction={direction}
        in={isVisible}
        mountOnEnter={!scrollOnMobile}
        container={currentRef}
        timeout={duration}
        easing={easing}
      >
        {children}
      </Slide>
    </div>
  )
}
export default SlideOnVisible
